<template>
  <form-view
    :url="`/user/train/${!dataForm.id ? 'save' : 'updateById'}`"
    :data-form="dataForm"
    v-loading="loading"
  >
    <el-row :gutter="25">
      <el-col :span="8">
        <el-form-item label="真实姓名" prop="userId">
          <el-select filterable v-model="dataForm.userId" @change="changeUser">
            <el-option v-for="s in userList" :key="s.id" :label="s.nickName" :value="s.id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label-width="120px" label="用户名" prop="nickName">
          <el-input disabled v-model="dataForm.userName"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label-width="120px" label="公司名称" prop="companyName">
          <el-input disabled v-model="dataForm.companyName"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="25">
      <el-col :span="8">
        <el-form-item label="培训内容" prop="attrId">
          <el-select filterable v-model="dataForm.attrId" @change="selectTrain">
            <el-option v-for="s in trainList" :key="s.dictId" :label="s.dictName" :value="s.dictId"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label-width="120px" label="培训开始时间" prop="attrBeginDate">
          <el-date-picker value-format="timestamp" v-model="dataForm.attrBeginDate"></el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label-width="120px" label="培训结束时间" prop="attrEndDate">
          <el-date-picker value-format="timestamp" v-model="dataForm.attrEndDate"></el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <el-form-item label-width="120px" label="有效期截止时间" prop="closingDate">
          <el-date-picker value-format="timestamp" v-model="dataForm.closingDate"></el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="25">
      <el-col :span="24">
        <el-form-item label="备注" prop="remarks">
          <el-input v-model="dataForm.remarks"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
  </form-view>
</template>

<script>
import FormView from '@/components/templates/form-view'
import dayjs from 'dayjs'

export default {
  name: 'person-train-update',

  components: { FormView },

  data () {
    return {
      loading: false,
      dataForm: {},
      userList: [],
      companyList: [],
      trainList: []
    }
  },

  created () {
    this.init()
  },

  methods: {
    async init () {
      const id = this.$route.query.id
      this.loading = true
      if (id) {
        const data = await this.$http({
          url: this.$http.adornUrl('/user/train/getById'),
          method: 'post',
          data: id
        })
        if (data && data.code === 200) {
          this.dataForm = {
            ...data.datas
          }
          this.dataForm.attrId = parseInt(this.dataForm.attrId)
          this.dataForm.attrBeginDate = dayjs(this.dataForm.attrBeginDate).valueOf()
          this.dataForm.attrEndDate = dayjs(this.dataForm.attrEndDate).valueOf()
          this.dataForm.closingDate = dayjs(this.dataForm.closingDate).valueOf()
          this.loading = false
        }
      } else {
        this.dataForm = {
          userId: '',
          nickName: '',
          companyName: '',
          attrId: '',
          attrName: '',
          attrBeginDate: '',
          attrEndDate: '',
          closingDate: '',
          remarks: ''
        }
        this.loading = false
      }
      let data = await this.$http({
        url: this.$http.adornUrl('/user/list'),
        method: 'post',
        data: {}
      })
      this.userList = data.datas
      data = await this.$http({
        url: this.$http.adornUrl('/company/list'),
        method: 'post',
        data: {}
      })
      this.companyList = data.datas
      data = await this.$http({
        url: this.$http.adornUrl('/user/train/dict/list'),
        method: 'post'
      })
      this.trainList = data.datas
    },

    changeUser (userID) {
      const user = this.userList.find(u => u.id === userID)
      this.dataForm.userName = user.userName
      const company = this.companyList.find(c => c.id === user.companyId)
      company && (this.dataForm.companyName = company.companyName)
    },

    selectTrain (id) {
      const train = this.trainList.find(t => t.dictId === id)
      train && (this.dataForm.attrName = train.dictName)
    }
  }
}
</script>
